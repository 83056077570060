import React, {useEffect, useRef, useState} from 'react';
import {STRINGS} from '../../constants/strings';
import {URL} from '../../constants/urls';
import '../../css/components/_lead-generation.scss';

import {validateEmail} from '../../utils/articleUtil';
import MaterialDatePicker from '../datePicker/date-picker';
import {navigate} from 'gatsby';

const FreddieForm = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [buyForYourself, setBuyForYourself] = useState('');
  const [tnc, setTnC] = useState(false);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);

  const refs = {
    firstName: useRef(null),
    lastName: useRef(null),
    email: useRef(null),
    buyForYourself: useRef(null),
    tnc: useRef(null)
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    setFirstName(params.firstName || '');
    setLastName(params.lastName || '');
    setEmailAddress(params.email || '');
  }, []);

  const onActionClick = async (action) => {
    switch (action) {
      case 'yes':
        if (validateForm()) {
          setError({});
          setLoading(true);
          const name = `${firstName} ${lastName}`;
          let url = `${URL.SERVER}/subscribe?name=${name}&email=${emailAddress}`;
          let tags = `FREDDIES_FLOWER,${buyForYourself}`;
          let list = 'FREDDIES_FLOWER';
          url += `&tag=${tags}&list=${list}`;

          fetch(url)
          .then(resp => resp.json())
            .then(res => {
              setLoading(false);
              setFirstName('');
              setLastName('');
              setEmailAddress('');
              setBuyForYourself('');
              setTnC(false);
              navigate(`/freddie-thankyou${buyForYourself === 'FREDDIE_ALREADY_SUBSCRIBED' ? '-subscribed' : ''}`);
            });

        break;
      }
    }
  };

  const validateForm = () => {
    let errors = {};
    let firstErrorField = null;
    if (!firstName.trim()) {
      errors.firstName = 'Please enter first name';
      firstErrorField = refs.firstName.current;
    }
    if (!lastName.trim()) {
      errors.lastName = 'Please enter last name';
      if (!firstErrorField) firstErrorField = refs.lastName.current;
    }
    if (!emailAddress || !validateEmail(emailAddress)) {
      errors.email = 'Please enter a valid email address';
      if (!firstErrorField) firstErrorField = refs.email.current;
    }
    if (!buyForYourself.trim()) {
      errors.buyForYourself = 'Please select an option';
      if (!firstErrorField) firstErrorField = refs.buyForYourself.current;
    }
    if (!tnc) {
      errors.tnc = 'Please accept Terms and Privacy Policy';
      if (!firstErrorField) firstErrorField = refs.tnc.current;
    }

    setError(errors);

    if (firstErrorField && typeof firstErrorField.scrollIntoView === 'function') {
      firstErrorField.scrollIntoView({ behavior: 'smooth', inline: 'start' });
      firstErrorField.focus();
    }

    return Object.keys(errors).length === 0;
  };

  const radioOptions = [
    {
      label: 'Yes',
      tag: 'BUY_FOR_YOURSELF_YES',
      class: 'col-span-6 lg:col-span-2',
    },
    {
      label: 'No',
      tag: 'BUY_FOR_YOURSELF_NO',
      class: 'col-span-6 lg:col-span-2',
    },
    {
      label: 'I’m already subscribed to Freddie’s',
      tag: 'FREDDIE_ALREADY_SUBSCRIBED',
      class: 'col-span-12 lg:col-span-8'
    }
  ]

  return (
    <div className="subscription-form">
      <div className="container">
        <h3>Win a FREE six-month subscription to Freddie's Flowers!</h3>
        {error.submit && <div className="text-red">{error.submit}</div>}
        <div className="input-container">
          <label htmlFor="firstName">First name</label>
          <input
            id="firstName"
            type="text"
            placeholder="Enter your first name"
            value={firstName}
            onChange={(event) => {
              setError(prevError => ({ ...prevError, firstName: '' }));
              setFirstName(event.target.value);
            }}
            ref={refs.firstName}
          />
          {error.firstName && <div className="text-red">{error.firstName}</div>}
        </div>
        <div className="input-container">
          <label htmlFor="lastName">Last name</label>
          <input
            id="lastName"
            type="text"
            placeholder="Enter your last name"
            value={lastName}
            onChange={(event) => {
              setError(prevError => ({ ...prevError, lastName: '' }));
              setLastName(event.target.value);
            }}
            ref={refs.lastName}
          />
          {error.lastName && <div className="text-red">{error.lastName}</div>}
        </div>
        <div className="input-container">
          <label htmlFor="email">Email address</label>
          <input
            id="email"
            type="email"
            placeholder="Enter your email address"
            value={emailAddress}
            onChange={(event) => {
              setError(prevError => ({ ...prevError, email: '' }));
              setEmailAddress(event.target.value);
            }}
            ref={refs.email}
          />
          {error.email && <div className="text-red">{error.email}</div>}
        </div>
        <div className="input-container">
          <label>Do you regularly buy fresh flowers for yourself?</label>
          <div className="grid grid-cols-12 mt-2 mb-4" ref={refs.buyForYourself}>
            {radioOptions.map(option => {
              return <div className={`flex items-center ${option.class}`}>
                <input
                  style={{marginTop: 0}}
                  className="cursor-pointer"
                  type="radio"
                  value={option.tag}
                  checked={buyForYourself === option.tag}
                  onChange={(event) => {
                    setError(prevError => ({...prevError, buyForYourself: ''}));
                    setBuyForYourself(event.target.value);
                  }}
                />
                {option.label}
              </div>;
            })}
            {error.buyForYourself && <div className="text-red col-span-12">{error.buyForYourself}</div>}
          </div>
        </div>
        <div className="checkbox-container">
          <input
            type="checkbox"
            id="tnc"
            checked={tnc}
            onChange={() => setTnC(!tnc)}
            ref={refs.tnc}
          />
          <label htmlFor="tnc">I have read and accept the <a className="cursor-pointer underline"
                                                             href="/freddie-terms" target="_blank">Privacy Policy and Terms & Conditions</a>.</label>
        </div>
        {error.tnc && <div className="text-red -mt-[15px] mb-4">{error.tnc}</div>}
        <div className="action-container mt-3">
          <button
            onClick={() => onActionClick('yes')}
            type="button"
            className="bg-red py-4 px-12 text-white rounded-[19px]"
          >
            SUBMIT MY ENTRY {loading && <img alt="loading" src="/images/loading.svg" style={{ marginLeft: '5px' }} />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FreddieForm;
