import {isMobile} from 'react-device-detect';
import {isBrowser} from './articleUtil';
import {addTimeout} from './timeManager';

const definedSlots = {};
let isOnRenderSet = false;
let previousPageCalledForAds = '';
const SLOT_ID_BASE_PATH = process.env.GATSBY_DOMAIN === '.com' ? '/22218985137/atcom' : '/22218985137/at';

const shouldDisplayAds = () => {
  /*if (process.env.GATSBY_DOMAIN === '.com') {
    addTimeout(() => {
      if(isBrowser()) {
        document.querySelectorAll('.ad-container').forEach(node => {
          node.style.display = "none";
        })
      }
    }, 10)
    return false;
  }*/
  return true;
};

const setTargeting = (targetingArray) => {
  targetingArray && targetingArray.forEach(targeting => {
    googletag.pubads()
      .setTargeting(targeting.key, targeting.value);
  });
  if (process.env.GATSBY_DEPLOYMENT_ENV && process.env.GATSBY_DEPLOYMENT_ENV !== '') {
    googletag.pubads()
      .setTargeting('env', process.env.GATSBY_DEPLOYMENT_ENV);
  }
};

const clearTargeting = (targetingArray) => {
  targetingArray && targetingArray.forEach(targeting => {
    googletag.pubads()
      .clearTargeting(targeting);
  });
};

export const refreshAds = (page) => {
  try {
    let slots = [];
    if (definedSlots['bottom_anchor']) {
      slots.push(definedSlots['bottom_anchor']);
    }
    if (definedSlots['oneByOneAdUnit']) {
      slots.push(definedSlots['oneByOneAdUnit']);
    }

    if (definedSlots[page]) {
      slots = slots.concat(definedSlots[page]);
    }

    switch (page) {
      case 'article_page':
        if (definedSlots['at_article_rightcol_desktop']) {
          slots.push(definedSlots['at_article_rightcol_desktop']);
        }
        try {
          stpd.refreshAdUnit('at_article_endcontent');
        } catch (e) {
          console.log(e);
        }
        break;
      case 'category_page':
      case 'subcategory_page':
        if (definedSlots['at_rightcol_desktop']) {
          slots.push(definedSlots['at_rightcol_desktop']);
        }
        break;
    }

    googletag.pubads()
      .refresh(slots, {changeCorrelator: true});
  } catch (e) {
    console.log(e);
  }
};
const addPreBidScript = () => {
  if (isBrowser()) {
    const headElement = document.getElementsByTagName('head')[0];
    const gptScript = document.createElement('script');
    gptScript.src = `https://stpd.cloud/saas/${process.env.GATSBY_DOMAIN === '.co.uk' ? '7200' : '7202'}`;
    gptScript.defer = true;
    headElement.appendChild(gptScript);
  }
};

const setOnRender = () => {
  !isOnRenderSet && googletag.pubads()
    .addEventListener('slotRenderEnded', onRender);
  isOnRenderSet = true;
};

const onRender = (event) => {
  const slot = event.slot;
  console.log('slot id: ', slot.getSlotElementId());
  console.log('isEmpty: ', event.isEmpty);
  console.log('=============================');

  const isEmpty = event.isEmpty;

  const element = document.getElementById(slot.getSlotElementId());
  if (element) {
    const parentContainer = element.parentNode;
    const children = parentContainer.childNodes;
    let adTextNode;
    /*let isEmpty;*/
    for (let i = 0; i < children.length; i++) {
      if (children[i].className === 'advertisement-text') {
        adTextNode = children[i];
        break;
      }
    }
    if (!adTextNode) {
      return;
    }
    /*isEmpty = !(element.children && element.children[0] && element.children[0].childElementCount);*/
    console.log('slot id: ', slot.getSlotElementId());
    console.log('isEmpty: ', isEmpty);
    console.log('----------------------------');
    if (isEmpty) {
      adTextNode && (adTextNode.style.opacity = 0);
      parentContainer.style.display = 'none';
    } else {
      adTextNode && (adTextNode.style.opacity = 1);
      parentContainer.style.display = 'block';
    }
  }
};

export const destroyPreviousAdSlots = () => {
  if (previousPageCalledForAds === '') {
    return;
  }
  try {
    googletag && googletag.destroySlots(definedSlots[previousPageCalledForAds]);
  } catch (e) {
    console.log(e);
  }

  definedSlots[previousPageCalledForAds] = null;
};

export const destroyLongerAdSlots = () => {
  definedSlots['longerAds'] && googletag && googletag.destroySlots(definedSlots['longerAds']);
  definedSlots['longerAds'] = null;
};

export const rightSide = (divId) => {
  window.googletag = window.googletag || {cmd: []};
  let slot1;
  if (!isMobile && !definedSlots[divId]) {
    googletag.cmd.push(() => {
      slot1 = googletag
        .defineSlot(
          `${SLOT_ID_BASE_PATH}/at_rightcol_desktop`,
          [[300, 250], [300, 600], 'fluid'],
          divId
        );
      if (slot1) {
        slot1.addService(googletag.pubads());
        definedSlots[divId] = slot1;
      }
    });
  }
};

export const bottomAnchor = () => {
  window.googletag = window.googletag || {cmd: []};

  if (isMobile && !definedSlots['bottom_anchor']) {
    googletag.cmd.push(() => {
      const anchorSlot = googletag.defineOutOfPageSlot(
        `${SLOT_ID_BASE_PATH}/at_sticky`,
        googletag.enums.OutOfPageFormat.BOTTOM_ANCHOR
      );
      if (anchorSlot) {
        anchorSlot.addService(googletag.pubads());
        definedSlots['bottom_anchor'] = anchorSlot;
      } else {
        console.log('Anchor Slot Not available');
      }
    });
  }
};

export const interstitial = () => {
  return; // returning because interstitial ads are causing INP
  window.googletag = window.googletag || {cmd: []};

  if (!definedSlots['interstitial']) {
    googletag.cmd.push(() => {
      const interstitialSlot = googletag.defineOutOfPageSlot(
        `${SLOT_ID_BASE_PATH}/interstitial`,
        googletag.enums.OutOfPageFormat.INTERSTITIAL);

      // Slot returns null if the page or device does not support interstitials.
      if (interstitialSlot) {
        interstitialSlot.addService(googletag.pubads());
        definedSlots['interstitial'] = interstitialSlot;
      } else {
        console.log('interstitial Slot Not available');
      }
    });
  }
};

export const oneByOneAdUnit = () => {
  window.googletag = window.googletag || {cmd: []};

  if (!definedSlots['oneByOneAdUnit']) {
    googletag.cmd.push(() => {
      const adSlot = googletag.defineSlot(
        `${SLOT_ID_BASE_PATH}/special`,
        [1, 1],
        'special'
      );

      if (adSlot) {
        adSlot.addService(googletag.pubads());
        definedSlots['oneByOneAdUnit'] = adSlot;
      }
    });
  }
};

export const homePageAds = () => {
  if (!shouldDisplayAds()) {
    return;
  }
  window.googletag = window.googletag || {cmd: []};
  if (previousPageCalledForAds !== 'home_page') {
    destroyPreviousAdSlots();
  }
  previousPageCalledForAds = 'home_page';

  bottomAnchor();
  interstitial();
  oneByOneAdUnit();

  if (definedSlots['home_page']) {
    return addTimeout(refreshAds, 50, 'home_page');
  }

  googletag.cmd.push(() => {
    let slot1, slot2, slot3;
    if (isMobile) {
      slot1 = googletag
        .defineSlot(
          `${SLOT_ID_BASE_PATH}/at_incontent1_mobile`,
          [[320, 50], [300, 100], 'fluid'],
          'at_incontent1'
        );
      slot2 = googletag
        .defineSlot(
          `${SLOT_ID_BASE_PATH}/at_incontent2_mobile`,
          [[320, 50], [300, 100], 'fluid'],
          'at_incontent2'
        );
      /*slot3 = googletag
        .defineSlot(
          `${SLOT_ID_BASE_PATH}/at_endcontent_mobile`,
          [[320, 50], [300, 100], 'fluid'],
          'at_endcontent'
        )*/
    } else {
      slot1 = googletag
        .defineSlot(
          `${SLOT_ID_BASE_PATH}/at_incontent1_desktop`,
          [[970, 250], [728, 90], 'fluid'],
          'at_incontent1'
        );
      slot2 = googletag
        .defineSlot(
          `${SLOT_ID_BASE_PATH}/at_incontent2_desktop`,
          [[970, 250], [728, 90], 'fluid'],
          'at_incontent2'
        );
      /*slot3 = googletag
        .defineSlot(
          `${SLOT_ID_BASE_PATH}/at_endcontent_desktop`,
          [[728, 90], [970, 250], 'fluid'],
          'at_endcontent'
        )*/
    }
    definedSlots['home_page'] = [];

    slot1 && slot1.addService(googletag.pubads()) && definedSlots['home_page'].push(slot1);
    slot2 && slot2.addService(googletag.pubads()) && definedSlots['home_page'].push(slot2);
    slot3 && slot3.addService(googletag.pubads()) && definedSlots['home_page'].push(slot3);

    setTargeting([{key: 'page', value: 'homepage'}]);

    googletag.pubads()
      .enableSingleRequest();
    googletag.pubads()
      .collapseEmptyDivs();
    googletag.pubads()
      .setCentering(true);
    googletag.pubads()
      .enableLazyLoad();
    clearTargeting(['category', 'subcategory', 'slug']);
    setOnRender();
    googletag.enableServices();

    googletag.display('at_incontent1');
    googletag.display('at_incontent2');
  });
};

export const articlePageAds = (targetingArray, longAdCount) => {
  if (!shouldDisplayAds()) {
    return;
  }
  console.log('====================articlePageAds=========================');
  window.googletag = window.googletag || {cmd: []};
  if (previousPageCalledForAds !== 'article_page') {
    destroyPreviousAdSlots();
  }
  previousPageCalledForAds = 'article_page';

  bottomAnchor();
  interstitial();
  rightSide('at_article_rightcol_desktop');
  oneByOneAdUnit();

  if (longAdCount) {
    googletag.cmd.push(() => {
      destroyLongerAdSlots();
      for (let i = 1; i <= longAdCount; i++) {
        let slot;
        if (isMobile) {
          slot = googletag
            .defineSlot(
              `${SLOT_ID_BASE_PATH}/at_incontent${i + 3}_mobile`,
              [[300, 250], [336, 280], [300, 600], 'fluid'],
              `at_article_incontent-0${i}`
            )
            .addService(googletag.pubads());
        } else {
          slot = googletag
            .defineSlot(
              `${SLOT_ID_BASE_PATH}/at_incontent${i + 3}_desktop`,
              ['fluid', [970, 250], [728, 90]],
              `at_article_incontent-0${i}`
            )
            .addService(googletag.pubads());
        }
        if (slot) {
          definedSlots['longerAds'] = definedSlots['longerAds'] || [];
          definedSlots['longerAds'].push(slot);
        }
      }
    });
  }

  if (definedSlots['article_page']) {
    setTargeting(targetingArray);
    return addTimeout(() => {
      refreshAds('article_page');
      for (let i = 1; i <= longAdCount; i++) {
        googletag.display(`at_article_incontent-0${i}`);
      }
    }, 50);
  }

  googletag.cmd.push(() => {
    let slot1, slot2, slot3, slot4;
    if (isMobile) {
      slot1 = googletag
        .defineSlot(
          `${SLOT_ID_BASE_PATH}/at_incontent1_mobile`,
          [[300, 250], [336, 280], 'fluid'],
          'at_article_incontent1'
        )
        .addService(googletag.pubads());
      slot2 = googletag
        .defineSlot(
          `${SLOT_ID_BASE_PATH}/at_incontent2_mobile`,
          [[300, 250], [336, 280], [300, 600], 'fluid'],
          'at_article_incontent2'
        )
        .addService(googletag.pubads());
      slot3 = googletag
        .defineSlot(
          `${SLOT_ID_BASE_PATH}/at_incontent3_mobile`,
          [[300, 250], [336, 280], [300, 600], 'fluid'],
          'at_article_incontent3'
        )
        .addService(googletag.pubads());
      slot4 = googletag
        .defineSlot(
          `${SLOT_ID_BASE_PATH}/at_endcontent_mobile`,
          [[300, 250], [336, 280], 'fluid'],
          'at_article_endcontent'
        )
        .addService(googletag.pubads());
    } else {
      slot1 = googletag
        .defineSlot(
          `${SLOT_ID_BASE_PATH}/at_incontent1_desktop`,
          [[970, 250], [728, 90], 'fluid'],
          'at_article_incontent1'
        )
        .addService(googletag.pubads());
      slot2 = googletag
        .defineSlot(
          `${SLOT_ID_BASE_PATH}/at_incontent2_desktop`,
          [[970, 250], [728, 90], 'fluid'],
          'at_article_incontent2'
        )
        .addService(googletag.pubads());
      slot3 = googletag
        .defineSlot(
          `${SLOT_ID_BASE_PATH}/at_incontent3_desktop`,
          ['fluid', [970, 250], [728, 90]],
          'at_article_incontent3'
        )
        .addService(googletag.pubads());
      slot4 = googletag
        .defineSlot(
          `${SLOT_ID_BASE_PATH}/at_endcontent_desktop`,
          [[728, 90], [970, 250], 'fluid'],
          'at_article_endcontent'
        )
        .addService(googletag.pubads());
    }

    setTargeting(targetingArray);
    googletag.pubads()
      .enableSingleRequest();
    googletag.pubads()
      .collapseEmptyDivs();
    googletag.pubads()
      .setCentering(true);
    googletag.pubads()
      .enableLazyLoad({
      // Fetch slots within 1 viewport.
      fetchMarginPercent: 100,
      renderMarginPercent: 0,
      mobileScaling: 0.0,
    });
    setOnRender();

    googletag.pubads()
      .disableInitialLoad();
    googletag.enableServices();

    addPreBidScript();

    definedSlots['article_page'] = [];
    slot1 && definedSlots['article_page'].push(slot1);
    slot2 && definedSlots['article_page'].push(slot2);
    slot3 && definedSlots['article_page'].push(slot3);
    slot4 && definedSlots['article_page'].push(slot4);

    googletag.display('at_article_incontent1');
    googletag.display('at_article_incontent2');
    googletag.display('at_article_incontent3');
    googletag.display('at_article_endcontent');
    googletag.display('at_article_rightcol_desktop');

    for (let i = 1; i <= longAdCount; i++) {
      googletag.display(`at_article_incontent-0${i}`);
    }
  });
};

export const categoryPageAds = (targetingArray) => {
  if (!shouldDisplayAds()) {
    return;
  }
  window.googletag = window.googletag || {cmd: []};
  if (previousPageCalledForAds !== 'category_page') {
    destroyPreviousAdSlots();
  }
  previousPageCalledForAds = 'category_page';

  bottomAnchor();
  interstitial();
  oneByOneAdUnit();

  if (definedSlots['category_page']) {
    setTargeting(targetingArray);
    return addTimeout(refreshAds, 50, 'category_page');
  }

  let slot1, slot2;
  googletag.cmd.push(() => {
    if (isMobile) {
      slot1 = googletag
        .defineSlot(
          `${SLOT_ID_BASE_PATH}/at_incontent1_mobile`,
          [[300, 250], [336, 280], 'fluid'],
          'at_category_incontent1'
        )
        .addService(googletag.pubads());
      slot2 = googletag
        .defineSlot(
          `${SLOT_ID_BASE_PATH}/at_incontent2_mobile`,
          [[300, 250], [336, 280], [300, 600], 'fluid'],
          'at_category_incontent2'
        )
        .addService(googletag.pubads());
      /*slot3 = googletag
        .defineSlot(
          `${SLOT_ID_BASE_PATH}/at_incontent3_mobile`,
          [[300, 250], [336, 280], [300, 600], 'fluid'],
          'at_category_incontent3'
        )
        .addService(googletag.pubads());
      slot4 = googletag
        .defineSlot(
          `${SLOT_ID_BASE_PATH}/at_endcontent_mobile`,
          [[300, 250], [336, 280], 'fluid'],
          'at_category_endcontent'
        )
        .addService(googletag.pubads());*/
    } else {
      slot1 = googletag
        .defineSlot(
          `${SLOT_ID_BASE_PATH}/at_incontent1_desktop`,
          [[970, 250], [728, 90], 'fluid'],
          'at_category_incontent1'
        )
        .addService(googletag.pubads());
      slot2 = googletag
        .defineSlot(
          `${SLOT_ID_BASE_PATH}/at_incontent2_desktop`,
          [[970, 250], [728, 90], 'fluid'],
          'at_category_incontent2'
        )
        .addService(googletag.pubads());
      /*slot3 = googletag
        .defineSlot(
          `${SLOT_ID_BASE_PATH}/at_incontent3_desktop`,
          ['fluid', [970, 250], [728, 90]],
          'at_category_incontent3'
        )
        .addService(googletag.pubads());
      slot4 = googletag
        .defineSlot(
          `${SLOT_ID_BASE_PATH}/at_endcontent_desktop`,
          [[728, 90], [970, 250], 'fluid'],
          'at_category_endcontent'
        )
        .addService(googletag.pubads());*/
    }

    setTargeting(targetingArray);
    googletag.pubads()
      .enableSingleRequest();
    googletag.pubads()
      .collapseEmptyDivs();
    googletag.pubads()
      .setCentering(true);
    googletag.pubads()
      .enableLazyLoad();
    clearTargeting(['subcategory', 'slug']);
    setOnRender();
    googletag.enableServices();

    definedSlots['category_page'] = [];
    slot1 && definedSlots['category_page'].push(slot1);
    slot2 && definedSlots['category_page'].push(slot2);

    googletag.display('at_category_incontent1');
    googletag.display('at_category_incontent2');
    /*googletag.display("at_category_incontent3");
    googletag.display("at_category_endcontent");*/
  });
};

export const subCategoryPageAds = (targetingArray) => {
  if (!shouldDisplayAds()) {
    return;
  }
  window.googletag = window.googletag || {cmd: []};
  if (previousPageCalledForAds !== 'subcategory_page') {
    destroyPreviousAdSlots();
  }
  previousPageCalledForAds = 'subcategory_page';

  bottomAnchor();
  interstitial();
  rightSide('at_rightcol_desktop');
  oneByOneAdUnit();

  if (definedSlots['subcategory_page']) {
    setTargeting(targetingArray);
    return addTimeout(refreshAds, 50, 'subcategory_page');
  }

  let slot1;
  googletag.cmd.push(() => {
    if (isMobile) {
      slot1 = googletag
        .defineSlot(
          `${SLOT_ID_BASE_PATH}/at_incontent1_mobile`,
          [[300, 250], [336, 280], 'fluid'],
          'at_subcategory_incontent1'
        )
        .addService(googletag.pubads());
    } else {
      slot1 = googletag
        .defineSlot(
          `${SLOT_ID_BASE_PATH}/at_incontent1_desktop`,
          [[970, 250], [728, 90], 'fluid'],
          'at_subcategory_incontent1'
        )
        .addService(googletag.pubads());
    }

    setTargeting(targetingArray);
    googletag.pubads()
      .enableSingleRequest();
    googletag.pubads()
      .collapseEmptyDivs();
    googletag.pubads()
      .setCentering(true);
    googletag.pubads()
      .enableLazyLoad();
    clearTargeting(['slug']);
    setOnRender();
    googletag.enableServices();
    definedSlots['subcategory_page'] = [];
    definedSlots['subcategory_page'].push(slot1);

    googletag.display('at_subcategory_incontent1');
    googletag.display('at_rightcol_desktop');
  });
};
